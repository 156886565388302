import { Venue } from "../models/models";
import { VenueAddress } from "./venue-address";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { FaCalendar, FaCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

type VenueProps = {
  venue: Venue;
  show_map?: boolean;
};
function getGoogleQuery(venue: Venue) {
  return `${venue.name} ${venue.address} ${venue.city?.name} ${venue.province} ${venue.postalCode}`;
}
function getGoogleMapsLink(venue: Venue): string {
  return `https://maps.google.com/?q=${getGoogleQuery(venue)}`;
}

function getGoogleMapsApiKey(): string {
  return process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string;
}
export function Venue({ venue, show_map = false }: VenueProps) {
  const card_background_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.card_background_color),
  );

  return (
    <div
      className={`grid-cols-1 p-4`}
      style={{ backgroundColor: card_background_color }}
    >
      <div className="grid grid-cols-2 gap-8">
        <div className={"flex flex-col p-2 gap-y-2"}>
          <h2 className="card-title">{venue.name}</h2>
          {venue.is_virtual ? (
            <div>{venue.description}</div>
          ) : (
            <div>
              <VenueAddress venue={venue} />
            </div>
          )}
          <div className={"card-actions"}>
            {!venue.is_virtual && (
              <Link
                target={"_blank"}
                href={getGoogleMapsLink(venue)}
                className="btn btn-outline btn-xs"
              >
                Google Maps
              </Link>
            )}
            <Link
              className={"btn btn-outline btn-xs"}
              href={`/x/${venue.slug}`}
            >
              See more events at this venue
            </Link>
          </div>
        </div>
        <div className={"p-2"}>
          <Image
            height={300}
            width={300}
            src={venue.thumbnail}
            alt={venue.name}
            className={"object-fill"}
          />
        </div>
      </div>
      {!venue.is_virtual && show_map && (
        <div className={"flex justify-center mt-3"}>
          <iframe
            width="450"
            height="250"
            style={{ border: 0 }}
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${getGoogleMapsApiKey()}&q=${getGoogleQuery(venue)}`}
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
}
