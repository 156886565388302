import { ProductOption } from "@medusajs/medusa";
import { RadioGroup } from "@headlessui/react";
import { classNames, Product, ProductVariant } from "@models/models";
import { VariantInfo } from "./variant-info";
import StockIndicator from "./stock-indicator";
import React from "react";
import VariantPriceDisplay from "@components/variant-price-display";

type ProductVariantOptionsProps = {
  product: Product;
  setVariantId: (variantId: string) => void;
  onPayWhatYouCanPriceChange?: (price: number) => void;
};

export default function ProductVariantOptions({
  product,
  setVariantId,
  onPayWhatYouCanPriceChange,
}: ProductVariantOptionsProps) {
  return (
    <>
      {product.options.map((o: ProductOption) => (
        <RadioGroup
          defaultValue={
            product.activeVariants.length !== 0
              ? product.activeVariants[0].id
              : ""
          }
          key={o.id}
        >
          <RadioGroup.Label className="block text-sm font-medium text-gray-700">
            {product.isPass ? "Price" : o.title}
          </RadioGroup.Label>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {(product.activeVariants.length !== 0
              ? product.activeVariants
              : product.inactiveVariants
            ).map((v: ProductVariant) => (
              <RadioGroup.Option
                as="div"
                onClick={() => setVariantId(v.id)}
                key={v.id}
                value={v.id}
                className={({ active }) =>
                  classNames(
                    active ? "ring-2 ring-indigo-500" : "",
                    "relative block border border-gray-300 rounded-lg p-2 cursor-pointer focus:outline-none",
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <RadioGroup.Label
                      as="span"
                      className="text-base font-medium text-gray-900"
                    >
                      {!product.is_giftcard && <VariantInfo variant={v} />}
                      <VariantPriceDisplay
                        variant={v}
                        onPayWhatYouCanPriceChange={onPayWhatYouCanPriceChange}
                      />
                    </RadioGroup.Label>
                    {!product.is_giftcard && (
                      <RadioGroup.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        <StockIndicator
                          low_inventory_message={v.low_inventory_message}
                          inventory_quantity={v.inventory_quantity}
                          original_inventory_quantity={
                            v.original_inventory_quantity
                          }
                          lowInventoryWarningThreshold={
                            v.lowInventoryWarningThreshold
                          }
                        />
                      </RadioGroup.Description>
                    )}
                    <div
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-500" : "border-transparent",
                        "absolute -inset-px rounded-lg pointer-events-none",
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      ))}
    </>
  );
}
